import { useState, useEffect } from "react";
// router
import { useNavigate, useLocation } from "react-router-dom";
// language
import { useTranslation } from "react-i18next";
import i18n from "../locales/i18n";
// mantine
import {
  createStyles,
  Header,
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Anchor,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  Container,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconNotification,
  IconCode,
  IconBook,
  IconChartPie3,
  IconFingerprint,
  IconCoin,
  IconChevronDown,
  IconX,
} from "@tabler/icons-react";
// components
import Logo from "./Logo";
import MobileNavbarItems from "./MobileNavbarItems";
import Footer from "./Footer";

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 100,
    border: 0,
    padding: "0 10vw",
    // backgroundColor: "#f8f9fa",
    transition: "background-color 500ms ease",
  },

  transparentHeader: {
    // backgroundColor: "transparent",
    backgroundColor: "rgba(248, 249, 250, 0.01)",
    transition: "background-color 500ms ease",
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    // flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    padding: 0,
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    // textTransform: "uppercase",
    textDecoration: "none",
    fontSize: rem(20),
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    padding: `${rem(7)} ${theme.spacing.sm}`,
    fontWeight: 300,
    borderBottom: `${rem(2)} solid transparent`,
    transition: "border-color 100ms ease, color 100ms ease",

    "&:hover": {
      borderBottomColor:
        theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
      fontWeight: 500,
    },
  },

  linkActive: {
    borderBottomColor: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

const mockdata = [
  {
    icon: IconCode,
    title: "Open source",
    description: "This Pokémon’s cry is very loud and distracting",
  },
  {
    icon: IconCoin,
    title: "Free for everyone",
    description: "The fluid of Smeargle’s tail secretions changes",
  },
  {
    icon: IconBook,
    title: "Documentation",
    description: "Yanma is capable of seeing 360 degrees without",
  },
  {
    icon: IconFingerprint,
    title: "Security",
    description: "The shell’s rounded shape and the grooves on its.",
  },
  {
    icon: IconChartPie3,
    title: "Analytics",
    description: "This Pokémon uses its flying ability to quickly chase",
  },
  {
    icon: IconNotification,
    title: "Notifications",
    description: "Combusken battles with the intensely hot flames it spews",
  },
];

export default function HeaderMegaMenu() {
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const { classes, theme, cx } = useStyles();
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group noWrap align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon size={rem(22)} color={theme.fn.primaryColor()} />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
          <Text size="xs" color="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== active) {
      setActive(location.pathname);
    }
  }, [location.pathname]);

  const dummyLinks = [
    { link: "/about", label: t("titles.about") },
    { link: "/team", label: t("titles.team") },
    { link: "/projects", label: t("titles.projects") },
    { link: "/contact", label: t("titles.contact") },
  ];

  const items = dummyLinks.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={cx(classes.link, {
        [classes.linkActive]: active === link.link,
      })}
      onClick={(event) => {
        event.preventDefault();
        navigate(link.link);
        setActive(link.link);
        close();
      }}
    >
      {link.label}
    </a>
  ));

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.pageYOffset > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box>
      <Header
        height={HEADER_HEIGHT}
        className={cx(classes.root, {
          [classes.transparentHeader]: !isScrolled,
        })}
      >
        <Container className={classes.header} style={{ maxWidth: "1400px" }}>
          <Logo />
          <Group spacing={5} className={classes.links}>
            {items}
            <a
              key={"language"}
              href={"/"}
              className={cx(classes.link, {
                [classes.linkActive]: active === "/language",
              })}
              onClick={(event) => {
                event.preventDefault();
                changeLanguage(i18n.language === "tr" ? "en" : "tr");

                close();
              }}
            >
              {i18n.language === "tr" ? "English" : "Türkçe"}
            </a>
          </Group>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Container>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title={<Logo />}
        className={classes.hiddenDesktop}
        zIndex={1000000}
        closeButtonProps={{ size: "xl" }}
      >
        <ScrollArea mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />
          <MobileNavbarItems closeDrawer={closeDrawer} />
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
