import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

// project imports
import MainLayout from "../layout/MainLayout";
// components
import Loadable from "../components/Loadable";
// pages
const Home = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Team = Loadable(lazy(() => import("../pages/Team")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Projects = Loadable(lazy(() => import("../pages/Projects")));
const ProjectDetail = Loadable(lazy(() => import("../pages/ProjectDetail")));
const NotFound = Loadable(lazy(() => import("../pages/NotFound")));
const Mail = Loadable(lazy(() => import("../pages/Mail")));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/team",
          element: <Team />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/projects",
          element: <Projects />,
        },
        {
          path: "/projects/:id",
          element: <ProjectDetail />,
        },
        {
          path: "/mail",
          element: <Mail />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);
}
