import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// mantine
import { useMantineTheme } from "@mantine/core";
import { Paper, Text, Flex } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  isCollapsed: PropTypes.bool,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useMantineTheme();
  const isDarkMode = theme.colorScheme === "dark";
  const isMdScreen = useMediaQuery("(max-width: 768px)");

  return (
    <Paper
      padding="md"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "end",
        background: "transparent",
        ...(sx || {}),
      }}
      component={disabledLink ? "div" : RouterLink}
      to="/"
    >
      <img
        style={{ margin: "0 auto" }}
        width={isMdScreen ? 70 : 85}
        src={
          isDarkMode
            ? "https://ideamimarlik.com/static/IdeaWhite.svg"
            : "https://ideamimarlik.com/static/IdeaBlack.svg"
        }
        alt="idea logo"
      />
      <Flex direction="column" gap={0} s>
        <Text fw={300} size="sm" lh={1}>
          MİMARLIK
        </Text>
        <Text fw={300} size="sm" lh={1}>
          ATÖLYESİ
        </Text>
      </Flex>
    </Paper>
  );
}
