import {
  createStyles,
  Text,
  Container,
  ActionIcon,
  Group,
  rem,
  Center,
  Anchor,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconPrinter,
  IconPhone,
  IconMail,
} from "@tabler/icons-react";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(20),
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.white,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: "5vh 10vw 5vh 10vw",
    [theme.fn.smallerThan("md")]: {
      padding: "5vh 10vw 5vh 10vw",
    },
  },

  logo: {
    maxWidth: rem(260),
    display: "flex",
    flexDirection: "column",
    alignItems: "start",

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  description: {
    marginTop: rem(20),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],

    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
      textAlign: "center",
    },
  },

  inner: {
    display: "flex",
    maxWidth: 1400,
    width: "100%",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  groups: {
    display: "flex",
    flexWrap: "wrap",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      //   display: "none",
    },
  },

  wrapper: {
    width: rem(190),
    marginRight: theme.spacing.xl,
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      marginTop: theme.spacing.xl,
      marginRight: 0,
    },
  },

  link: {
    display: "flex",
    alignItems: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    paddingTop: rem(3),
    paddingBottom: rem(3),
    [theme.fn.smallerThan("sm")]: {
      justifyContent: "center",
      textAlign: "center",
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  afterFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    maxWidth: 1400,
    width: "100%",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  social: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

export default function FooterLinks() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Logo />
          <Text size="sm" className={classes.description}>
            {t("footer.description")}
          </Text>
        </div>
        <div className={classes.groups}>
          <div className={classes.wrapper}>
            <Text className={classes.title}>{t("footer.phone")}</Text>
            <Text
              className={classes.link}
              component="a"
              href="tel:+902128738228"
            >
              <IconPhone size="1rem" />
              &nbsp;+90 212 873 82 28
            </Text>
            <Text
              className={classes.link}
              component="a"
              href="tel:+902128739571"
            >
              <IconPrinter size="1rem" />
              &nbsp;+90 212 873 95 71
            </Text>
          </div>
          <div className={classes.wrapper}>
            <Text className={classes.title}>{t("footer.email")}</Text>
            <Text
              className={classes.link}
              component="a"
              href="mailto:mail@ideamimarlik.com"
            >
              <IconMail size="1rem" /> &nbsp;mail@ideamimarlik.com
            </Text>
          </div>
          <div className={classes.wrapper}>
            <Text className={classes.title}>{t("footer.address")}</Text>
            <Text
              className={classes.link}
              component="a"
              href="https://goo.gl/maps/mfXcHqx85RoUGRwM9"
              target="_blank"
            >
              Barış Mah. Atakan Bulvarı Mehmetçik Cad. Peyami Safa Sok.
              Semerkand Line Sitesi N Blok D:3 Beylikdüzü-İSTANBUL
            </Text>
          </div>
        </div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text size="sm">
          © {currentYear} İdea Mimarlık. {t("footer.copyright")}
        </Text>

        <Group spacing={0} className={classes.social} position="right" noWrap>
          <Anchor
            href="https://linkedin.com/company/i̇dea-mimarlık-atölyesi"
            target="_blank"
          >
            <ActionIcon size="lg">
              <IconBrandLinkedin size="1.5rem" stroke={1.5} />
            </ActionIcon>
          </Anchor>
          <Anchor
            href="https://www.instagram.com/ideamimarlikatolyesi/"
            target="_blank"
          >
            <ActionIcon size="lg">
              <IconBrandInstagram size="1.5rem" stroke={1.5} />
            </ActionIcon>
          </Anchor>
        </Group>
      </Container>
    </footer>
  );
}
