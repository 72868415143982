// router
import { useNavigate } from "react-router-dom";
// language
import { useTranslation } from "react-i18next";
import i18n from "../locales/i18n";
//
import {
  createStyles,
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
  Anchor,
  Group,
  rem,
  Center,
} from "@mantine/core";
import {
  IconHome2,
  IconInfoCircle,
  IconUsers,
  IconBuildingCommunity,
  IconAddressBook,
  IconLanguage,
  IconReport,
  IconCashBanknote,
  IconCoin,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: 700,
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.lg,
    boxShadow: theme.shadows.md,
    height: rem(120),
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
  },
}));

export default function ActionsGrid({ closeDrawer }) {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const mockdata = [
    { title: t("titles.home"), icon: IconHome2, color: "dark", target: "/" },
    {
      title: t("titles.about"),
      icon: IconInfoCircle,
      color: "dark",
      target: "/about",
    },
    {
      title: t("titles.team"),
      icon: IconUsers,
      color: "dark",
      target: "/team",
    },
    {
      title: t("titles.projects"),
      icon: IconBuildingCommunity,
      color: "dark",
      target: "/projects",
    },
    {
      title: t("titles.contact"),
      icon: IconAddressBook,
      color: "dark",
      target: "/contact",
    },
    //   { title: "1", icon: IconReport, color: "pink" },
    //   { title: "2", icon: IconCoin, color: "red" },
    //   { title: "3", icon: IconCashBanknote, color: "orange" },
  ];

  const items = mockdata.map((item) => (
    <UnstyledButton
      key={item.title}
      className={classes.item}
      onClick={() => {
        navigate(item.target);
        closeDrawer();
      }}
    >
      <item.icon color={theme.colors[item.color][6]} size="2rem" />
      <Text size="xs" mt={7}>
        {item.title}
      </Text>
    </UnstyledButton>
  ));

  return (
    <Card radius="md" className={classes.card} style={{ height: "90vh" }}>
      <SimpleGrid cols={2} mt="md">
        {items}
        <UnstyledButton
          key={"language"}
          className={classes.item}
          onClick={() => {
            changeLanguage(i18n.language === "tr" ? "en" : "tr");
            closeDrawer();
          }}
        >
          <IconLanguage color={theme.colors.dark[6]} size="2rem" />
          <Text size="xs" mt={7}>
            {i18n.language === "tr" ? "English" : "Türkçe"}
          </Text>
        </UnstyledButton>
      </SimpleGrid>
    </Card>
  );
}
