import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
// routing
import Routes from "./routes";
// redux
import { useSelector, useDispatch } from "react-redux";
import { setImages } from "./redux/settingsSlice";
// components
import Loader from "./components/Loader";

export default function App() {
  const { images } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  useEffect(() => {
    fetch("https://ideamimarlik.com/images.json", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setImages(result));
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <HelmetProvider>
      {images !== null ? (
        <Routes />
      ) : (
        <>
          <Helmet>
            <title>İdea Mimarlık</title>
          </Helmet>
          <div style={{ height: "100vh" }}>
            <Loader />
          </div>
        </>
      )}
    </HelmetProvider>
  );
}
