import { Outlet } from "react-router-dom";
import { MantineProvider, AppShell } from "@mantine/core";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function MainLayout() {
  return (
    <MantineProvider
      theme={{
        colorScheme: "light",
        fontFamily: "Urbanist, sans-serif",
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <AppShell
        header={<Header />}
        footer={<Footer />}
        styles={(theme) => ({
          main: { padding: 0, minHeight: "calc(100vh - 40vh)" },
        })}
      >
        <Outlet />
      </AppShell>
    </MantineProvider>
  );
}
