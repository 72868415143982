import React from "react";
import { Loader as MantineLoader } from "@mantine/core";

// styles
const loaderWrapperStyle = {
  zIndex: 1301,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

// ==============================|| LOADER ||============================== //
const Loader = () => (
  <div style={loaderWrapperStyle}>
    <MantineLoader color="dark" />
  </div>
);

export default Loader;
